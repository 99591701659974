import { Component, OnInit } from '@angular/core';
import { IDatasets } from '../../interfaces/i-datasets';
import { ApiService } from '../../services/api.service';
import { HttpErrorResponse } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrl: './landing.component.scss',
})
export class LandingComponent implements OnInit {
  public datasets: IDatasets[] = [];
  public error: HttpErrorResponse | undefined;

  constructor(private api: ApiService, public spinner: NgxSpinnerService) {}

  ngOnInit(): void {
    this.spinner.show();
    this.api.getDatasets().subscribe({
      next: (result) => {
        this.datasets = result;
        this.spinner.hide();
      },
      error: (err: HttpErrorResponse) => {
        this.error = err;
        this.spinner.hide();
      },
    });
  }
}
