import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LandingComponent } from './pages/landing/landing.component';
import { PublicationsComponent } from './pages/publications/publications.component';
import { SponsorsComponent } from './pages/sponsors/sponsors.component';
import { DisplayGeneComponent } from './pages/display-gene/display-gene.component';
import { ComingSoonComponent } from './pages/coming-soon/coming-soon.component';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', component: LandingComponent, title: 'GEM•HD | Home' },
  {
    path: 'publications',
    component: PublicationsComponent,
    title: 'GEM•HD | Publications',
  },
  {
    path: 'sponsors',
    component: SponsorsComponent,
    title: 'GEM•HD | Sponsors',
  },
  { path: 'about', component: ComingSoonComponent, title: 'GEM•HD | About' },
  {
    path: 'contact',
    component: ComingSoonComponent,
    title: 'GEM•HD | Contact',
  },
  {
    path: 'downloads',
    component: ComingSoonComponent,
    title: 'GEM•HD | Downloads',
  },
  { path: 'help', component: ComingSoonComponent, title: 'GEM•HD | Help' },
  {
    path: 'display/:id',
    component: DisplayGeneComponent,
    title: 'GEM•HD | Discover',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { bindToComponentInputs: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
