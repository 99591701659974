<app-logo-with-subtitle></app-logo-with-subtitle>
<div class="margin-inline-auto width-fit margin-top-1">
  <h4>Our Sponsors</h4>
</div>
<div class="flex-wrap column-gap-2">
  <nb-card *ngFor="let sponsor of staticContent" class="width-4">
    <nb-card-body>
      <div class="width-fit height-3 margin-inline-auto" style="white-space: nowrap;">
        <span style="vertical-align: middle; height: 100%; display: inline-block;"></span>
        <img [src]="sponsor.image" class="card-icon" />
      </div>
      <div class="text-large margin-top-1">
        <b>{{ sponsor.title }}</b>
      </div>
      <div class="text-small margin-top-1">{{ sponsor.description }}</div>
    </nb-card-body>
  </nb-card>
</div>
