import { Injectable } from '@angular/core';
import { AuthConfig, OAuthService } from 'angular-oauth2-oidc';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserAuthService {
  commonConfig: AuthConfig = {
    scope: `openid profile email offline_access`,
    responseType: 'code',
    strictDiscoveryDocumentValidation: false,
    skipIssuerCheck: true,
  };
  private userProfile: object | undefined;
  constructor(private oauthService: OAuthService) {
    const config = Object.assign(environment.oauthConfig, this.commonConfig);
    this.oauthService.configure(config);
    this.oauthService.events.subscribe({
      next: (e) => {
        console.log(e);
      },
      error: (e) => {
        console.error(e);
      },
    });
  }

  init(): Promise<void> {
    return new Promise(async (resolve, reject) => {
      await this.oauthService.tryLogin().catch((e) => {
        console.error('Login Error', e);
        reject();
      });
      if (this.loggedIn()) {
        this.oauthService.setupAutomaticSilentRefresh();
        resolve();
        // this.oauthService
        //   .loadUserProfile()
        //   .then((profile) => {
        //     this.userProfile = profile;
        //     console.log('User Profile', this.userProfile);
        //     resolve();
        //   })
        //   .catch((e) => {
        //     console.error('Cannot Load user Profile', e);
        //     reject();
        //   });
      } else {
        this.oauthService.initLoginFlow();
      }
    });
  }

  loggedIn(): boolean {
    return this.oauthService.hasValidAccessToken();
  }

  getToken(): string {
    return this.oauthService.getAccessToken();
  }

  logout() {
    this.oauthService.logOut();
  }
}
