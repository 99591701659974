[
    {
        "title":"CHDI Foundation",
        "description":"CHDI Foundation is a privately-funded, nonprofit biomedical research organization devoted to a single disease – Huntington’s disease (HD). Our mission is to develop therapeutics that will provide meaningful clinical benefit to patients as quickly as possible.",
        "image":"assets/static-data/images/chdi.png"
    },
    {
        "title":"Massachusetts General Hospital",
        "description":"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        "image":"assets/static-data/images/mgh.png"
    },
    {
        "title":"Harvard University",
        "description":"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        "image":"assets/static-data/images/harvard.png"
    },
    {
        "title":"Center for Human Genetic Research",
        "description":"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        "image":"assets/static-data/images/chgr.png"
    },
    {
        "title":"Cardiff University",
        "description":"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        "image":"assets/static-data/images/cardiff.png"
    },
    {
        "title":"Huntington Study Group",
        "description":"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        "image":"assets/static-data/images/hsg.png"
    },
    {
        "title":"Huntington's Disease Society of America",
        "description":"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        "image":"assets/static-data/images/hdsa.png"
    },
    {
        "title":"Predict-HD",
        "description":"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        "image":"assets/static-data/images/predict-hd.png"
    }
]