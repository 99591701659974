[
    {
        "title":"Identification of Genetic Factors that Modify Clinical Onset of Huntington's Disease",
        "description":"As a Mendelian neurodegenerative disorder, the genetic risk of Huntington's disease (HD) is conferred entirely by an HTT CAG repeat expansion whose length is the primary determinant of the rate of pathogenesis leading to disease onset. To investigate the pathogenic process that precedes disease, we used genome-wide association (GWA) analysis to identify loci harboring genetic variations that alter the age at neurological onset of HD. A chromosome 15 locus displays two independent effects that accelerate or delay onset by 6.1 years and 1.4 years, respectively, whereas a chromosome 8 locus hastens onset by 1.6 years. Association at MLH1 and pathway analysis of the full GWA results support a role for DNA handling and repair mechanisms in altering the course of HD. Our findings demonstrate that HD disease modification in humans occurs in nature and offer a genetic route to identifying in-human validated therapeutic targets in this and other Mendelian disorders.",
        "pmid":"26232222",
        "url":"https://pubmed.ncbi.nlm.nih.gov/26232222/",
        "image":"assets/static-data/images/publications-1.png"
    },
    {
        "title":"The Genetic Modifiers of Motor OnsetAge (GeM MOA) Website: Genome-wide Association Analysis for Genetic Modifiers of Huntington's Disease",
        "description":"Background: Huntington's disease (HD) is a dominantly inherited disease caused by a CAG expansion mutation in HTT. The age at onset of clinical symptoms is determined primarily by the length of this CAG expansion but is also influenced by other genetic and/or environmental factors.\n\nObjective: Recently, through genome-wide association studies (GWAS) aimed at discovering genetic modifiers, we identified loci associated with age at onset of motor signs that are significant at the genome-wide level. However, many additional HD modifiers may exist but may not have achieved statistical significance due to limited power.\n\nMethods: In order to disseminate broadly the entire GWAS results and make them available to complement alternative approaches, we have developed the internet website \"GeM MOA\" where genetic association results can be searched by gene name, SNP ID, or genomic coordinates of a region of interest.\n\nResults: Users of the Genetic Modifiers of Motor Onset Age (GeM MOA) site can therefore examine support for association between any gene region and age at onset of HD motor signs. GeM MOA's interactive interface also allows users to navigate the surrounding region and to obtain association p-values for individual SNPs.\n\nConclusions: Our website conveys a comprehensive view of the genetic landscape of modifiers of HD from the existing GWAS, and will provide the means to evaluate the potential influence of genes of interest on the onset of HD. GeM MOA is freely available at https://www.hdinhd.org/.",
        "pmid":"26444025",
        "url":"https://pubmed.ncbi.nlm.nih.gov/26444025/",
        "image":"assets/static-data/images/publications-1.png"
    },
    {
        "title":"CAG Repeat Not Polyglutamine Length Determines Timing of Huntington's Disease Onset",
        "description":"Variable, glutamine-encoding, CAA interruptions indicate that a property of the uninterrupted HTT CAG repeat sequence, distinct from the length of huntingtin's polyglutamine segment, dictates the rate at which Huntington's disease (HD) develops. The timing of onset shows no significant association with HTT cis-eQTLs but is influenced, sometimes in a sex-specific manner, by polymorphic variation at multiple DNA maintenance genes, suggesting that the special onset-determining property of the uninterrupted CAG repeat is a propensity for length instability that leads to its somatic expansion. Additional naturally occurring genetic modifier loci, defined by GWAS, may influence HD pathogenesis through other mechanisms. These findings have profound implications for the pathogenesis of HD and other repeat diseases and question the fundamental premise that polyglutamine length determines the rate of pathogenesis in the \"polyglutamine disorders.\"",
        "pmid":"31398342",
        "url":"https://pubmed.ncbi.nlm.nih.gov/31398342/",
        "image":"assets/static-data/images/publications-1.png"
    },
    {
        "title":"Association Analysis of Chromosome X to Identify Genetic Modifiers of Huntington's Disease",
        "description":"Background: Huntington's disease (HD) is caused by an expanded (>35) CAG trinucleotide repeat in huntingtin (HTT). Age-at-onset of motor symptoms is inversely correlated with the size of the inherited CAG repeat, which expands further in brain regions due to somatic repeat instability. Our recent genetic investigation focusing on autosomal SNPs revealed that age-at-onset is also influenced by genetic variation at many loci, the majority of which encode genes involved in DNA maintenance/repair processes and repeat instability.\n\nObjective: We performed a complementary association analysis to determine whether variants in the X chromosome modify HD.\n\nMethods: We imputed SNPs on chromosome X for ∼9,000 HD subjects of European ancestry and performed an X chromosome-wide association study (XWAS) to test for association with age-at-onset corrected for inherited CAG repeat length.\n\nResults: In a mixed effects model XWAS analysis of all subjects (males and females), assuming random X-inactivation in females, no genome-wide significant onset modification signal was found. However, suggestive significant association signals were detected at Xq12 (top SNP, rs59098970; p-value, 1.4E-6), near moesin (MSN), in a region devoid of DNA maintenance genes. Additional suggestive signals not involving DNA repair genes were observed in male- and female-only analyses at other locations.\n\nConclusion: Although not genome-wide significant, potentially due to small effect size compared to the power of the current study, our data leave open the possibility of modification of HD by a non-DNA repair process. Our XWAS results are publicly available at the updated GEM EURO 9K website hosted at https://www.hdinhd.org/ for browsing, pathway analysis, and data download.",
        "pmid":"34180418",
        "url":"https://pubmed.ncbi.nlm.nih.gov/34180418/",
        "image":"assets/static-data/images/publications-1.png"
    },
    {
        "title":"Genetic modifiers of somatic expansion and clinical phenotypes in Huntington's disease reveal shared and tissue-specific effects",
        "description":"Huntington's disease (HD), due to expansion of a CAG repeat in HTT, is representative of a growing number of disorders involving somatically unstable short tandem repeats. We find that overlapping and distinct genetic modifiers of clinical landmarks and somatic expansion in blood DNA reveal an underlying complexity and cell-type specificity to the mismatch repair-related processes that influence disease timing. Differential capture of non-DNA-repair gene modifiers by multiple measures of cognitive and motor dysfunction argues additionally for cell-type specificity of pathogenic processes. Beyond trans modifiers, differential effects are also illustrated at HTT by a 5'-UTR variant that promotes somatic expansion in blood without influencing clinical HD, while, even after correcting for uninterrupted CAG length, a synonymous sequence change at the end of the CAG repeat dramatically hastens onset of motor signs without increasing somatic expansion. Our findings are directly relevant to therapeutic suppression of somatic expansion in HD and related disorders and provide a route to define the individual neuronal cell types that contribute to different HD clinical phenotypes.",
        "pmid":"38948755",
        "url":"https://pubmed.ncbi.nlm.nih.gov/38948755/",
        "image":"assets/static-data/images/publications-1.png"
    }
]