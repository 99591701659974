import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-coming-soon',
  templateUrl: './coming-soon.component.html',
  styleUrl: './coming-soon.component.scss',
})
export class ComingSoonComponent {
  pageTitle: string | undefined;

  constructor(private titleService: Title) {}

  ngOnInit() {
    this.pageTitle = this.titleService.getTitle().split('|')[1];
  }
}
