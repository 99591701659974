<app-logo-with-subtitle></app-logo-with-subtitle>
<div class="margin-inline-auto width-fit margin-top-1">
  <h4>Publications</h4>
</div>
<nb-card *ngFor="let publication of staticContent">
  <nb-card-body>
    <div class="flex-x column-gap-2">
      <div class="width-fit margin-block-auto">
        <img [src]="publication.image" class="card-icon" />
      </div>
      <div>
        <div class="text-large">
          {{ publication.title }}
        </div>
        <div class="text-italic margin-top-1">PMID: <a [href]="publication.url" target="_blank">{{ publication.pmid }}</a></div>
        <div class="margin-top-1">{{ publication.description }}</div>
      </div>
    </div>
  </nb-card-body>
</nb-card>
