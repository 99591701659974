<div class="flex-x margin-inline-auto width-fit column-gap-1">
  <div>
    <img src="assets/icons/gem.png" class="" />
  </div>
  <div>
    <img src="assets/typeface-logo/GEM-HD-2.png" class="margin-top-1" />
  </div>
</div>
<div class="subtitle-text margin-top-1 margin-inline-auto width-fit">
  Genetic Modifiers of Huntington's Disease Consortium
</div>
