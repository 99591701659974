import { Component } from '@angular/core';
import staticContent from '../../../assets/static-data/publications.json';

@Component({
  selector: 'app-publications',
  templateUrl: './publications.component.html',
  styleUrl: './publications.component.scss',
})
export class PublicationsComponent {
  public staticContent = staticContent;
}
