import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { IDatasets } from '../interfaces/i-datasets';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private http: HttpClient) {}

  getDatasets(): Observable<IDatasets[]> {
    return this.http.get<IDatasets[]>(environment.baseUrl + 'manhattan/datasets');
  }

  getManhattanPlot(): Observable<string> {
    return this.http.get(environment.baseUrl + 'manhattan/plot', {
      responseType: 'text',
    });
  }
}
