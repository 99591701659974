import { AuthConfig } from 'angular-oauth2-oidc';


  const oauthConfig: AuthConfig = {
    loginUrl: 'https://portaldev.hdinhd.org/oauth/authorize/',
    clientId: 'qdPoo1tpannXONfibKdh3ivTMEWCsp',
    dummyClientSecret: 'HpfqO4LZnvDfrWApz0yv8wLo3HPcts',
    redirectUri: 'https://gem-dev.rbsapp.net/',
    tokenEndpoint: 'https://portaldev.hdinhd.org/oauth/token',
    userinfoEndpoint: 'https://portaldev.hdinhd.org/oauth/me/',
  };

  export const environment = {
    name: 'dev',
    prod: false,
    oauthConfig,
    baseUrl: 'https://gem-dev.rbsapp.net/api/',
  };
  