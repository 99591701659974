import { Component } from '@angular/core';
import { UserAuthService } from './services/user-auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  title = 'gem-hd-portal';
  constructor(
    public router: Router,
    private auth: UserAuthService) {}

  login() {
    this.auth.init();
  }

  logout() {
    this.auth.logout();
  }

  isLoggedIn(): boolean {
    return this.auth.loggedIn();
  }
}
