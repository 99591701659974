import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {
  NbButtonModule,
  NbCardModule,
  NbFormFieldModule,
  NbIconModule,
  NbInputModule,
  NbLayoutModule,
  NbSelectModule,
  NbSidebarModule,
  NbThemeModule,
} from '@nebular/theme';
import { LandingComponent } from './pages/landing/landing.component';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { LogoWithSubtitleComponent } from './components/logo-with-subtitle/logo-with-subtitle.component';
import { PublicationsComponent } from './pages/publications/publications.component';
import { SponsorsComponent } from './pages/sponsors/sponsors.component';
import { OAuthModule } from 'angular-oauth2-oidc';
import { environment } from '../environments/environment';
import { HttpClientModule } from '@angular/common/http';
import { DisplayGeneComponent } from './pages/display-gene/display-gene.component';
import { UserAuthService } from './services/user-auth.service';
import { ComingSoonComponent } from './pages/coming-soon/coming-soon.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxSpinnerModule } from 'ngx-spinner';

export function authFactory(auth: UserAuthService) {
  return () => auth.init();
}
@NgModule({
  declarations: [
    AppComponent,
    LandingComponent,
    LogoWithSubtitleComponent,
    PublicationsComponent,
    SponsorsComponent,
    DisplayGeneComponent,
    ComingSoonComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NbThemeModule.forRoot({ name: 'rancho-gem-theme' }),
    NbLayoutModule,
    NbButtonModule,
    NbSidebarModule.forRoot(),
    NbCardModule,
    NbInputModule,
    NbFormFieldModule,
    NbIconModule,
    NbEvaIconsModule,
    NbSelectModule,
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: [
          environment.baseUrl,
          environment.oauthConfig.userinfoEndpoint!,
        ],
        sendAccessToken: true,
      },
    }),
    HttpClientModule,
    NgxSpinnerModule.forRoot({ type: 'cog' }),
    BrowserAnimationsModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: authFactory,
      deps: [UserAuthService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
