<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * * * The content below * * * * * * * * * * * -->
<!-- * * * * * * * * * * is only a placeholder * * * * * * * * * * -->
<!-- * * * * * * * * * * and can be replaced.  * * * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * Delete the template below * * * * * * * * * -->
<!-- * * * * * * * to get started with your project! * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<nb-layout>
  <nb-layout-header fixed class="background-red">
    <div class="width-full flex-x-between">
      <img
        src="assets/typeface-logo/GEM-HD.svg"
        class="margin-block-auto margin-right-1 cursor-pointer"
        routerLink="/home"
      />
      <div class="flex-x column-gap-1">
        <button
          nbButton
          status="primary"
          routerLink="/about"
          [class.border-bottom-white]="router.url.startsWith('/about')"
        >
          About
        </button>
        <button
          nbButton
          status="primary"
          routerLink="/contact"
          [class.border-bottom-white]="router.url.startsWith('/contact')"
        >
          Contact
        </button>
        <button
          nbButton
          status="primary"
          routerLink="/publications"
          [class.border-bottom-white]="router.url.startsWith('/publications')"
        >
          Publications
        </button>
        <button
          nbButton
          status="primary"
          routerLink="/sponsors"
          [class.border-bottom-white]="router.url.startsWith('/sponsors')"
        >
          Sponsors
        </button>
        <button
          nbButton
          status="primary"
          routerLink="/downloads"
          [class.border-bottom-white]="router.url.startsWith('/downloads')"
        >
          Downloads
        </button>
        <button
          *ngIf="!isLoggedIn()"
          nbButton
          class="margin-left-2"
          shape="semi-round"
          size="small"
          (click)="login()"
        >
          Login/Sign-Up
        </button>
        <button
          *ngIf="isLoggedIn()"
          nbButton
          class="margin-left-2"
          shape="semi-round"
          size="small"
          (click)="logout()"
        >
          Logout
        </button>
      </div>
    </div>
  </nb-layout-header>

  <nb-layout-column class="margin-inline-auto max-width-6">
    <router-outlet></router-outlet>
  </nb-layout-column>

  <nb-layout-footer>
    <a class="width-fit margin-inline-auto cursor-pointer" routerLink="/help"
      >New to GEM-HD?</a
    >
  </nb-layout-footer>
</nb-layout>

<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * * * The content above * * * * * * * * * * * * -->
<!-- * * * * * * * * * * is only a placeholder * * * * * * * * * * * -->
<!-- * * * * * * * * * * and can be replaced.  * * * * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * * End of Placeholder  * * * * * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
