import { Component } from '@angular/core';
import staticContent from '../../../assets/static-data/sponsors.json';

@Component({
  selector: 'app-sponsors',
  templateUrl: './sponsors.component.html',
  styleUrl: './sponsors.component.scss'
})
export class SponsorsComponent {
  public staticContent = staticContent;

}
