<div class="margin-top-3">
  <app-logo-with-subtitle></app-logo-with-subtitle>
</div>
<div
  [hidden]="true"
  class="margin-inline-auto width-fit margin-top-2 flex-x column-gap-1"
>
  <div class="width-4">
    <nb-form-field>
      <nb-icon nbPrefix icon="search-outline"></nb-icon>
      <input
        nbInput
        placeholder="Search by Gene, SNP or Locus"
        shape="semi-round"
        fullWidth
        disabled
      />
    </nb-form-field>
  </div>
  <div>
    <nb-form-field>
      <nb-icon nbPrefix icon="paper-plane-outline"></nb-icon>
      <nb-select placeholder="Select Endpoint" shape="semi-round" disabled>
        <nb-option value="1">Endpoint 1</nb-option>
        <nb-option value="2">Endpoint 2</nb-option>
        <nb-option value="3">Endpoint 3</nb-option>
        <nb-option value="4">Endpoint 4</nb-option>
        <nb-option value="5">Endpoint 5</nb-option>
        <nb-option value="6">Endpoint 6</nb-option>
      </nb-select>
    </nb-form-field>
  </div>
</div>
<div class="margin-top-2 width-fit margin-inline-auto">
  <ngx-spinner
    color="#D73647"
    bdColor="#00000050"
    [fullScreen]="false"
    size="medium"
    ><div>Fetching Datasets ...</div></ngx-spinner
  >
  <div
    *ngIf="datasets.length > 0"
    class="text-center text-large margin-top-2 margin-bottom-1"
  >
    Datasets
  </div>
  <ng-container *ngFor="let dataset of datasets; let last = last">
    <a [routerLink]="'/display/' + dataset.name">{{
      dataset.display_name ? dataset.display_name : dataset.name
    }}</a>
    <span *ngIf="!last" class="text-large"
      >&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;</span
    >
  </ng-container>
</div>
<div *ngIf="error" class="margin-top-2 width-fit margin-inline-auto text-danger">
  ERROR {{ error.status }}: {{ error.message }}
</div>
