import { Component } from '@angular/core';

@Component({
  selector: 'app-logo-with-subtitle',
  templateUrl: './logo-with-subtitle.component.html',
  styleUrl: './logo-with-subtitle.component.scss'
})
export class LogoWithSubtitleComponent {

}
